var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResizeHandler,
          expression: "onResizeHandler",
        },
      ],
      staticClass: "pa-1 recount-view",
      style: "height: " + _vm.windowSize.y + "px; position: 'relative'",
      attrs: { flat: "" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 70,
                  width: 7,
                  color: "primary",
                  indeterminate: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "12" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("message.ccsheet.warehouse")) +
                      " " +
                      _vm._s(_vm.$t("message.ccsheet.reCount")) +
                      ": " +
                      _vm._s(_vm.sheetModel.warehousename) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "highlight pt-0 pb-0 pl-1 mt-2" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 font-weight-bold",
                  attrs: { cols: "4" },
                },
                [_vm._v(_vm._s(_vm.$t("message.whsDashboard.product")) + ":")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pr-2 text-center font-weight-bold",
                  attrs: { cols: "3" },
                },
                [_vm._v(_vm._s(_vm.$t("message.order.location")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 text-right font-weight-bold",
                  attrs: { cols: "2" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.stock")))]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 pl-3 text-right font-weight-bold",
                  attrs: { cols: "3" },
                },
                [_vm._v(_vm._s(_vm.$t("message.ccsheet.counted")))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "pa-0" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "ccsheetItemsCount",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _vm._l(_vm.listOfProducts, function (group, key) {
                return _vm._l(group, function (product, index) {
                  return _c(
                    "v-row",
                    { key: key + "_" + index + "_productitem" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 pl-3 text-truncate mx-auto-full",
                          attrs: { cols: "4", title: product.productnumber },
                        },
                        [
                          index === 0
                            ? _c("span", [
                                _vm._v(_vm._s(product.productnumber)),
                              ])
                            : _vm._e(),
                          _c("br"),
                          index === group.length - 1
                            ? _c("span", [_vm._v(_vm._s(product.productname))])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "pa-0 text-center text-truncate mx-auto-full",
                          attrs: { cols: "3", title: product.locationname },
                        },
                        [_vm._v(_vm._s(product.locationname))]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "pa-0 text-right text-truncate mx-auto-full",
                          attrs: { cols: "2", title: product.onstock },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("absoluteNumber")(product.onstock)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "pa-0 pb-1 pl-3 text-right text-truncate",
                          attrs: { cols: "3" },
                        },
                        [
                          _c("v-text-field", {
                            ref: "countQty",
                            refInFor: true,
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              autocomplete: "off",
                              rules: _vm.$_zeroValidation,
                              placeholder: _vm.$t("message.product.qty"),
                            },
                            model: {
                              value: product.counted,
                              callback: function ($$v) {
                                _vm.$set(product, "counted", $$v)
                              },
                              expression: "product.counted",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-2 complete_count_btn",
              attrs: {
                bottom: "",
                color: "primary",
                id: "scroll__top",
                tabindex: "-1",
                fixed: "",
                disabled: !_vm.valid,
                loading: _vm.saveLoading,
              },
              on: { click: _vm.completeRecounting },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("message.ccsheet.completeRecount")) + " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }